@import "https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&family=Tajawal:wght@200;300;400;500;700;800;900&display=swap";

/* Global Colors - The following color variables are used throughout the website. Updating them here will change the color scheme of the entire website */
/* Nav Menu Colors - The following color variables are used specifically for the navigation menu. They are separate from the global colors to allow for more customization options */
/* Smooth scroll */
:root {
  --default-font: "Geologica", sans-serif;
  --heading-font: "Geologica", sans-serif;
  --nav-font: "Geologica", sans-serif;
  --peragraph-font: "Tajawal", sans-serif;
  --background-color: #ffffff;
  --default-color: #212529;
  --heading-color: #32353a;
  --accent-color: #e84545;
  --surface-color: #ffffff;
  --contrast-color: #ffffff;
  --demande-btn-bgcolor: #DCDCDC;
  --black: #000000;
  --white: #ffffff;
  --white-50: #FFFFFF33;
  --orange: #EC6630;
  --gray-white: #E6E5E6;
  --offwhite: #454346;
  --nav-color: #3a3939;
  --nav-hover-color: #e84545;
  --nav-mobile-background-color: #ffffff;
  --nav-dropdown-background-color: #ffffff;
  --nav-dropdown-color: #3a3939;
  --nav-dropdown-hover-color: #e84545;
  scroll-behavior: smooth;
  --swiper-image-ratio: 33.3%;
  --swiper-width: 50%;
  --swiper-inactive-scale: .85;
  --swiper-mobile-width: 90%;
  --swiper-mobile-inactive-scale: .95;
}

.light-background {
  --background-color: #f9f9f9;
  --surface-color: #ffffff;
}

.dark-background {
  --background-color: #060606;
  --default-color: #ffffff;
  --heading-color: #ffffff;
  --surface-color: #252525;
  --contrast-color: #ffffff;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--default-font);
  overflow-x: hidden !important;
  height: 100%;
}

a {
  text-decoration: none;
  transition: 0.3s;
}

a:hover {
  text-decoration: none;
}

h1 {
  color: var(--heading-color);
  font-family: var(--default-font);
}

h2 {
  color: var(--heading-color);
  font-family: var(--default-font);
}

h3 {
  color: var(--heading-color);
  font-family: var(--default-font);
}

h4 {
  color: var(--heading-color);
  font-family: var(--default-font);
}

h5 {
  color: var(--heading-color);
  font-family: var(--default-font);
}

h6 {
  color: var(--heading-color);
  font-family: var(--default-font);
}

.black-divider {
  width: 100%;
  height: 1px;
  background-color: var(--black);
}

@media (max-width: 998px) {
  .black-divider {
    margin-top: 80px;
  }
}

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1360px !important;
    padding: 0;
  }
}

.container-fluid {
  margin: 0 40px;
  width: auto;
}

@media (max-width: 576px) {
  .container-fluid {
    margin: 0 20px;
    padding: 0;
  }
}

@media (max-width: 576px) {
  .container {
    padding: 0 28px;
  }
}

.hidden {
  display: none;
}

.section-index {
  position: relative;
  z-index: 9;
}

.services-button .service-btn {
  background-color: rgba(255, 255, 255, 0.3215686275);
  padding: 8px 12px 8px 12px;
  gap: 10px;
  border-radius: 8px;
  color: var(--white);
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  border: none;
}

/*--------------------------------------------------------------
# Global Header
--------------------------------------------------------------*/
.activeClass .header .logo .white-img {
  display: none;
}

.activeClass .header .logo .orange-img {
  display: block;
  max-height: 36px;
  margin-right: 8px;
}

@media screen and (max-width: 575px) {
  .activeClass .header .logo .orange-img {
    display: none;
  }
}

.header {
  max-width: 1200px;
  margin: 0 auto;
  color: var(--default-color);
  background-color: var(--background-color);
  padding: 40px 0;
  transition: all 0.5s;
  z-index: 997;
}

.header .logo {
  line-height: 1;
}

.header .logo .white-img {
  max-height: 36px;
  margin-right: 8px;
}

@media screen and (max-width: 575px) {
  .header .logo .white-img {
    display: none;
  }
}

.header .logo .orange-img {
  display: none;
  max-height: 36px;
  margin-right: 8px;
}

@media screen and (max-width: 575px) {
  .header .logo .orange-img {
    display: none;
  }
}

.header .logo .mobile-logo {
  display: none;
}

@media screen and (max-width: 575px) {
  .header .logo .mobile-logo {
    display: block;
  }
}

.header .navbar-btn-demande {
  background-color: var(--demande-btn-bgcolor);
  padding: 8px 8px 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 100px;
  gap: 12px;
  cursor: pointer;
  transition: all 0.5s;
}

.header .navbar-btn-demande a {
  text-decoration: none;
  display: flex;
  align-items: center;
}

.header .navbar-btn-demande p {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: var(--black);
  line-height: 17.5px;
}

.header .navbar-btn-demande button {
  border: none;
  height: 34px;
  width: 34px;
  border-radius: 50%;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-left: 10px;
}

.header .navbar-btn-demande:hover {
  background-color: var(--orange);
}

.header .navbar-btn-demande:hover p {
  color: var(--black);
}

.header .language-dropdown {
  display: none;
}

@media (max-width: 1199px) {
  .header .logo {
    order: 1;
  }

  .header .navbar-btn-demande {
    order: 2;
    margin: 0 15px 0 0;
    padding: 6px 20px;
    display: none;
  }

  .header .navmenu {
    order: 3;
  }
}

.scrolled .header {
  box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.1);
}

.scrolled .header .logo {
  line-height: 1;
}

.scrolled .header .logo .white-img {
  display: none;
}

.scrolled .header .logo .orange-img {
  max-height: 36px;
  margin-right: 8px;
  display: block;
}

/* Index Page Header
  ------------------------------*/
.index-page .header {
  --background-color: rgba(255, 255, 255, 0);
  --heading-color: #ffffff;
  --nav-color: rgb(255, 255, 255);
  --nav-hover-color: #ffffff;
  position: relative;
}

@media screen and (max-width: 575px) {
  .index-page .header .container {
    padding: 0 25px;
  }
}

/* Index Page Header on Scroll
  ------------------------------*/
.index-page.scrolled .header {
  --background-color: #ffffff;
  --heading-color: #32353a;
  --nav-color: #3a3939;
  --nav-hover-color: #e84545;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Navmenu - Desktop */
@media (min-width: 1200px) {
  .activeClass .navmenu a {
    color: var(--black) !important;
  }

  .navmenu {
    padding: 0;
  }

  .navmenu ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
    gap: 16px;
  }

  .navmenu li {
    position: relative;
  }

  .navmenu li:hover>a {
    color: var(--nav-hover-color);
    background-color: #dcdcdc;
  }

  .navmenu a {
    color: var(--nav-color);
    padding: 16px;
    border-radius: 100px;
    font-size: 14px;
    font-family: var(--default-font);
    font-weight: 300;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    transition: all 0.5s;
  }

  .navmenu a:focus {
    color: var(--nav-color);
    padding: 16px;
    border-radius: 100px;
    font-family: var(--default-font);
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    transition: 0.3s;
  }

  .navmenu a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
    transition: 0.3s;
  }

  .navmenu a i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
    transition: 0.3s;
  }

  .navmenu .active {
    color: var(--nav-hover-color);
    background-color: #dcdcdc;
  }

  .navmenu .active:focus {
    color: var(--nav-hover-color);
    background-color: #dcdcdc;
  }

  .navmenu .dropdown ul {
    margin: 0;
    padding: 10px 0;
    background: var(--nav-dropdown-background-color);
    display: block;
    position: absolute;
    visibility: hidden;
    left: 14px;
    top: 130%;
    opacity: 0;
    transition: 0.3s;
    border-radius: 4px;
    z-index: 99;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  }

  .navmenu .dropdown ul li {
    min-width: 200px;
  }

  .navmenu .dropdown ul li:hover>a {
    color: var(--nav-dropdown-hover-color);
  }

  .navmenu .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
    color: var(--nav-dropdown-color);
  }

  .navmenu .dropdown ul a i {
    padding: 16px;
    padding: 16px;
    border-radius: 100px;
    border-radius: 100px;
    font-size: 12px;
  }

  .navmenu .dropdown ul a:hover {
    color: var(--nav-dropdown-hover-color);
  }

  .navmenu .dropdown ul .active:hover {
    color: var(--nav-dropdown-hover-color);
  }

  .navmenu .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }

  .navmenu .dropdown .dropdown ul {
    top: 0;
    left: -90%;
    visibility: hidden;
  }

  .navmenu .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: -100%;
    visibility: visible;
  }
}

/* Navmenu - Mobile */
@media (max-width: 1199px) {
  .activeClass .mobile-nav-toggle {
    color: #1c1b1f;
  }

  .mobile-nav-toggle {
    color: var(--nav-color);
    font-size: 28px;
    line-height: 0;
    margin-right: 10px;
    cursor: pointer;
    transition: color 0.3s;
  }
}

@media screen and (max-width: 1199px) and (max-width: 575px) {
  .mobile-nav-toggle {
    color: #1c1b1f;
    margin: 0;
  }
}

@media (max-width: 1199px) {
  .navmenu {
    padding: 0;
    z-index: 9997;
  }

  .navmenu ul {
    display: none;
    position: absolute;
    inset: 60px 20px 20px 20px;
    padding: 10px 0;
    margin: 0;
    border-radius: 6px;
    background-color: var(--nav-mobile-background-color);
    overflow-y: auto;
    transition: 0.3s;
    z-index: 9998;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  }

  .navmenu a {
    color: var(--nav-dropdown-color);
    padding: 10px 20px;
    font-family: var(--default-font);
    font-size: 17px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    transition: 0.3s;
  }

  .navmenu a:focus {
    color: var(--nav-dropdown-color);
    padding: 10px 20px;
    font-family: var(--default-font);
    font-size: 17px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    transition: 0.3s;
  }

  .navmenu a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: 0.3s;
    background-color: color-mix(in srgb, var(--accent-color), transparent 90%);
  }

  .navmenu a:focus i:hover {
    background-color: var(--accent-color);
    color: var(--contrast-color);
  }

  .navmenu a i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: 0.3s;
    background-color: color-mix(in srgb, var(--accent-color), transparent 90%);
  }

  .navmenu a i:hover {
    background-color: var(--accent-color);
    color: var(--contrast-color);
  }

  .navmenu a:hover {
    color: var(--nav-dropdown-hover-color);
  }

  .navmenu .active {
    color: var(--nav-dropdown-hover-color);
  }

  .navmenu .active:focus {
    color: var(--nav-dropdown-hover-color);
  }

  .navmenu .active:focus i {
    background-color: var(--accent-color);
    color: var(--contrast-color);
    transform: rotate(180deg);
  }

  .navmenu .active i {
    background-color: var(--accent-color);
    color: var(--contrast-color);
    transform: rotate(180deg);
  }

  .navmenu .dropdown ul {
    position: static;
    display: none;
    z-index: 99;
    padding: 10px 0;
    margin: 10px 20px;
    background-color: var(--nav-dropdown-background-color);
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
    box-shadow: none;
    transition: all 0.5s ease-in-out;
  }

  .navmenu .dropdown ul ul {
    background-color: rgba(33, 37, 41, 0.1);
  }

  .navmenu .dropdown>.dropdown-active {
    display: block;
    background-color: rgba(33, 37, 41, 0.03);
  }

  .mobile-nav-active {
    overflow: hidden;
  }

  .mobile-nav-active .mobile-nav-toggle {
    color: var(--white);
    position: absolute;
    font-size: 32px;
    top: 15px;
    right: 15px;
    margin-right: 0;
    z-index: 9999;
  }

  .mobile-nav-active .navmenu {
    position: fixed;
    overflow: hidden;
    inset: 0;
    background: rgba(33, 37, 41, 0.8);
    transition: 0.3s;
  }

  .mobile-nav-active .navmenu>ul {
    display: block;
  }

  section {
    scroll-margin-top: 64px;
  }

  .section {
    scroll-margin-top: 64px;
  }
}

/*--------------------------------------------------------------
# Main Section
--------------------------------------------------------------*/
.active-main {
  margin-top: -141px;
  transition: all 0.5s;
}

@media screen and (max-width: 575px) {
  .active-main {
    margin-top: 0;
  }
}

.activeClass .active-main {
  margin-top: 0;
}

.main {
  margin-bottom: -35px;
  z-index: 99;
  position: relative;
}

.service-section {
  position: relative;
  z-index: 3;
}

.service-section::after {
  content: "";
  position: absolute;
  top: -33%;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(./assets/img/top-bg.svg) no-repeat center center;
  z-index: -1;
}

/*--------------------------------------------------------------
# Global Footer
--------------------------------------------------------------*/
.footer {
  background-color: #454346;
  padding: 80px 80px 90px 80px;
  position: relative;
  z-index: 1;
}

.footer::after {
  content: "";
  position: absolute;
  top: 0;
  left: -196px;
  width: 100%;
  height: 100%;
  background: url(./assets/img/footer-bg.svg) no-repeat center center;
  background-position-y: 100%;
  z-index: -1;
}

@media (max-width: 576px) {
  .footer::after {
    left: 0px;
  }
}

.footer .footer-details {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 24px;
}

.footer .footer-details .footer-section {
  max-width: 350px;
}

.footer .footer-details .footer-section .footer-pera {

  color: var(--white);
  font-family: "Tajawal", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  margin: 32px 0;
}

.footer .footer-details .footer-section .footer-pera.footer-second-pera {
  font-weight: 300;
}

.footer .footer-details .footer-section .footer-heading {
  color: var(--white);
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}

.footer .footer-details .footer-section .sos-icon {
  height: 20px;
  width: 20px;
  color: var(--white);
  margin-right: 16px;
}

.footer .footer-details .footer-section ul {
  padding-left: 0;
}

.footer .footer-details .footer-section ul .footer-links {
  list-style: none;
  padding: 6px 0;
  min-width: 180px;
}

.footer .footer-details .footer-section ul .footer-links a {
  text-decoration: none;
  font-family: "Tajawal", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: var(--white);
  position: relative;
  transition: all 0.5s;
  padding-left: 0px;
}

.footer .footer-details .footer-section ul .footer-links a::after {
  content: "";
  position: absolute;
  left: 0;
  top: 30%;
  transform: translateY(-50%);
  margin: auto;
  width: 14px;
  height: 11px;
  background-image: url(./assets/img/right-arrow.svg);
  background-repeat: no-repeat;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s;
}

.footer .footer-details .footer-section ul .footer-links a:hover {
  padding-left: 22px;
}

.footer .footer-details .footer-section ul .footer-links a:hover:after {
  visibility: visible;
  opacity: 1;
}

.footer .footer-divider-line {
  background-color: #c1c0c1;
  width: 100%;
  height: 1px;
  margin: 32px auto;
}

.footer .last-footer-lines {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer .last-footer-lines .l-footer-text {
  color: var(--white);
  font-family: "Tajawal", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  margin-bottom: 0;
}

.footer .last-footer-lines .l-footer-text .hor-line {
  min-width: 1px;
  color: var(--white);
  height: 100%;
}

.footer .scroll-top {
  height: 62px;
  width: 62px;
  background-color: var(--white);
  position: absolute;
  right: 50px;
  bottom: 200px;
  border-radius: 50%;
  color: black;
}

.footer .scroll-top i {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer .scroll-top:hover {
  background-color: var(--orange);
  color: var(--white);
}

@media (max-width: 992px) {
  .footer .footer-details {
    flex-wrap: wrap;
    margin-top: 60px;
  }

  .footer .footer-details .footer-section {
    max-width: 250px;
  }

  .footer .last-footer-lines {
    flex-wrap: wrap;
  }
}

@media (max-width: 576px) {
  .footer {
    padding: 20px;
  }

  .footer .scroll-top {
    margin: 40px 25px 0;
    position: unset;
  }
}

/*--------------------------------------------------------------
# Global Sections
--------------------------------------------------------------*/
section {
  color: var(--default-color);
  padding: 60px 0;
  scroll-margin-top: 98px;
  overflow: clip;
  padding: 0;
}

.section {
  color: var(--default-color);
  padding: 60px 0;
  scroll-margin-top: 98px;
  overflow: clip;
}

/*--------------------------------------------------------------
# Global Section Titles
--------------------------------------------------------------*/
.section-title {
  text-align: center;
  padding-bottom: 60px;
  position: relative;
}

.section-title h2 {
  font-size: 32px;
  font-weight: 700;
  position: relative;
}

.section-title h2:before {
  content: "";
  width: 50px;
  height: 2px;
  background: var(--accent-color);
  display: inline-block;
  margin: 0 15px 10px 0;
}

.section-title h2:after {
  content: "";
  width: 50px;
  height: 2px;
  background: var(--accent-color);
  display: inline-block;
  margin: 0 0 10px 15px;
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.activeClass .hero {
  margin: 0 40px;
  width: calc(100% - 80px);
  border-radius: 20px;
}

@media screen and (max-width: 575px) {
  .activeClass .hero {
    margin: 0 25px;
    width: calc(100% - 50px);
    border-radius: 20px;
  }
}

.hero {
  width: 100%;
  min-height: 100vh;
  overflow-y: hidden;
  position: relative;
  padding: 80px 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
}

@media screen and (max-width: 575px) {
  .hero {
    margin: 0 25px;
    width: calc(100% - 50px);
    border-radius: 20px;
  }
}

.hero video {
  position: absolute;
  inset: 0;
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  z-index: 1;
}

.hero:before {
  content: "";
  background: rgba(0, 0, 0, 0.16);
  position: absolute;
  inset: 0;
  z-index: 2;
}

.hero .container {
  position: relative;
  z-index: 3;
}

.hero-detail-section {
  display: flex;
  justify-content: space-between;
  align-items: end;
  gap: 124px;
}

.hero-detail .hero-text {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 32px;
}

.hero-detail .hero-text p {
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  color: var(--white);
  margin: 0;
}

.hero-detail .hero-text .divider-line {
  border: 1px solid var(--white);
  width: 23px;
}

.hero-detail h1 {
  font-size: 80px;
  font-weight: 400 !important;
  line-height: 92px;
  color: var(--white);
  margin-bottom: 124px;
}

.hero-pera {
  font-family: "Tajawal", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  color: var(--white);
  margin-top: 32px;
}

.divider-line {
  border: 1px solid var(--white);
  width: 77px;
}

/*............. demande card............ */
.demande-card {
  background-color: var(--white-50);
  padding: 28px;
  border-radius: 16px;
  min-width: 418px;
  max-height: 244;
}

.demande-card .card-question {
  display: flex;
  justify-content: start;
  align-items: start;
  gap: 12px;
  margin-bottom: 32px;
}

.demande-card .card-question p {
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
}

.demande-card .card-pera {
  margin: 0;
  font-family: "Tajawal", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 22.4px;
  margin-bottom: 32px;
}

.btn-demande {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 8px 8px 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 100px;
  gap: 12px;
  max-width: 300px;
  transition: all 0.5s;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  /* min-width: -moz-max-content;
  min-width: max-content; */
  cursor: pointer;
}

.btn-demande a {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.btn-demande p {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: var(--white);
  line-height: 17.5px;
}

.btn-demande button {
  border: none;
  height: 34px;
  width: 34px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-demande:hover {
  background-color: var(--white);
}

.btn-demande:hover p {
  color: var(--black);
}

.btn-demande:hover button {
  background-color: var(--orange);
}

@media (max-width: 1200px) {
  .hero-detail-section {
    margin-top: 150px;
  }

  .hero-detail h1 {
    font-size: 65px;
    line-height: 74px;
  }
}

@media (max-width: 992px) {
  .hero-detail-section {
    display: block;
    margin-top: 80px;
  }

  .hero-detail {
    margin: 60px 0px;
  }

  .hero-detail h1 {
    font-size: 54px;
    margin-bottom: 40px;
    line-height: 64px;
  }

  .hero-detail .hero-text {
    margin-bottom: 25px;
  }

  .demande-card {
    max-width: 418px;
  }
}

@media (max-width: 576px) {
  .hero {
    min-height: 70vh;
    padding: 80px 0;
  }

  .hero-detail-section {
    display: block;
    margin-top: 20px;
  }

  .hero-detail {
    margin: 0px 0px;
  }

  .hero-detail h1 {
    font-size: 42px;
    margin-bottom: 40px;
    line-height: 52px;
  }

  .hero-detail .hero-text {
    margin-bottom: 25px;
  }

  .demande-card {
    min-width: unset;
    background-color: transparent;
    padding: 0;
  }

  .demande-card .card-question {
    display: none;
  }

  .demande-card .card-pera {
    display: none;
  }

  .demande-card .btn-demande {
    background-color: var(--white-50);
  }

  .demande-card .btn-demande.btn-demande-center {
    margin: 0 auto;
  }
}

/* ................................services-section.............................. */
.services-details {
  text-align: center;
  padding-top: 90px;
}

.services-details .service-text-detail {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 32px auto;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.services-details .service-text-detail .divider-line {
  border: 1px solid var(--black);
  width: 23px;
}

.services-details h2 {
  color: var(--default-color);
  font-size: 48px;
  font-weight: 400;
  line-height: 55.2px;
  margin-bottom: 32px;
}

.services-details h2 span {
  color: var(--orange);
}

.services-details .service-pera {
  font-size: 20px;
  font-weight: 400;
  line-height: 25.6px;
  color: var(--default-color);
  font-family: "Tajawal", sans-serif;
}

.service-text-detail p {
  margin: 0;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  color: var(--default-color);
}

/* ................................Engagement-section.............................. */
.engagement-content {
  background-color: #F2F2F2;
  border-radius: 20px;
  padding: 80px;
  margin: 30px 0;
}

@media screen and (max-width: 991px) {
  .engagement-content {
    padding: 100px 20px 60px;
    margin: 20px 0;
  }
}

.engagement-content .engagement-text {
  max-width: 712px;
  width: 100%;
  margin-bottom: 60px;
}

.engagement-content .engagement-text h2 {
  color: #3F100B;
  font-size: 48px;
  line-height: 55px;
  font-weight: 400;
  margin-bottom: 32px;
}

@media screen and (max-width: 767px) {
  .engagement-content .engagement-text h2 {
    font-size: 32px;
    line-height: 36px;
  }
}

.engagement-content .engagement-text p {
  color: #000000;
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
  font-family: var(--peragraph-font);
  margin-bottom: 0;
}

.engagement-content .engagement-lists {
  list-style: none;
  padding: 0;
}

.engagement-content .engagement-lists li {
  border-top: 1px solid #000000;
  padding: 32px 0 40px 0;
  display: flex;
  transition: all 0.5s ease;
}

@media screen and (max-width: 767px) {
  .engagement-content .engagement-lists li {
    padding: 32px 0;
    flex-direction: column;
    gap: 16px;
  }
}

.engagement-content .engagement-lists li:hover {
  border-color: var(--orange);
  border-width: 2px;
}

@media screen and (max-width: 767px) {
  .engagement-content .engagement-lists li:hover {
    border-color: #000000;
    border-width: 1px;
  }
}

.engagement-content .engagement-lists li:hover h3 {
  color: var(--orange);
  opacity: 1;
  font-weight: 400;
  transition: all 0.5s ease;
}

@media screen and (max-width: 767px) {
  .engagement-content .engagement-lists li:hover h3 {
    color: var(--black);
  }
}

.engagement-content .engagement-lists li:hover p {
  opacity: 1;
}

.engagement-content .engagement-lists li h3 {
  color: var(--black);
  font-size: 28px;
  line-height: 38px;
  font-weight: 200;
  margin-bottom: 0;
  width: 50%;
  opacity: 0.4;
}

@media screen and (max-width: 767px) {
  .engagement-content .engagement-lists li h3 {
    font-size: 24px;
    line-height: 33px;
    opacity: 1;
    width: 100%;
  }
}

.engagement-content .engagement-lists li p {
  color: #3F100B;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  font-family: var(--peragraph-font);
  margin-bottom: 0;
  width: 50%;
  opacity: 0.4;
  transition: all 0.5s;
}

@media screen and (max-width: 767px) {
  .engagement-content .engagement-lists li p {
    font-weight: 300;
    opacity: 1;
    width: 100%;
  }
}

/* ................................Culture-section.............................. */
#culture-section {
  margin-top: 80px;
}

@media screen and (max-width: 767px) {
  #culture-section {
    margin-top: 80px;
  }
}

#culture-section .culture-content {
  justify-content: space-between;
  padding: 0 80px;
}

@media (max-width: 1200px) {
  #culture-section .culture-content {
    padding: 0 40px;
  }
}

@media screen and (max-width: 767px) {
  #culture-section .culture-content {
    gap: 60px;
    padding: 0 20px;
  }
}

#culture-section .culture-content .culture-right ul {
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

#culture-section .culture-content .culture-right ul li {
  background-color: #F2F2F2;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  gap: 32px;
}

@media screen and (max-width: 575px) {
  #culture-section .culture-content .culture-right ul li {
    flex-direction: column;
  }
}

#culture-section .culture-content .culture-right ul li .icon {
  flex-shrink: 0;
  width: 67px;
  height: 67px;
  border-radius: 100px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

#culture-section .culture-content .culture-right ul li .right-content h3 {
  color: var(--black);
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  margin-bottom: 16px;
}

#culture-section .culture-content .culture-right ul li .right-content p {
  color: #454346;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  font-family: var(--peragraph-font);
  margin-bottom: 0;
}

.hover-card__wrap {
  flex-flow: row;
  align-items: stretch;
  margin-top: 60px;
  margin-bottom: 120px;
  display: flex;
  gap: 32px;
}

.hover-card {
  color: var(--color--white);
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-start;
  max-width: 50%;
  transition: max-width 3s cubic-bezier(0.19, 1, 0.22, 1), flex 3s cubic-bezier(0.19, 1, 0.22, 1);
  position: relative;
}

.hover-card:hover {
  flex: 2 auto;
  max-width: 66.7%;
}

.hover-card {
  color: var(--color--white);
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-start;
  max-width: 50%;
  transition: max-width 3s cubic-bezier(0.19, 1, 0.22, 1), flex 3s cubic-bezier(0.19, 1, 0.22, 1);
  position: relative;
}

.hover-card__inner {
  border-radius: var(--radius--desktop);
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  height: 33em;
  display: flex;
  position: relative;
  overflow: hidden;
  background: #efefef;
}

.hover-card__inner:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(229.24deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  border-radius: 10px;
}

.cover-img {
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 0.75rem;
  width: 100%;
  max-width: none;
  height: 100%;
}

.hover-card__bg {
  z-index: 0;
  background-color: var(--color--peach);
  /* background: linear-gradient(229.24deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%); */
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0px 60px;
  transition: transform var(--d) var(--e);
  z-index: 1;
}

@media screen and (max-width: 1400px) {
  .content {
    padding: 0 40px;
  }
}

@media screen and (max-width: 575px) {
  .content {
    padding: 0 20px;
  }
}

.content>*+* {
  margin-top: 1rem;
}

.heading {
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1.2;
}

.data-content {
  font-family: var(--font-serif);
  font-size: 1.125rem;
  font-style: italic;
  line-height: 1.35;
}

.d_card:after {
  transform: translateY(0);
}

.content {
  transform: translateY(calc(100% - 185px));
}

.started-content {
  transform: translateY(calc(100% - 148px));
}

.content>*:not(.heading) {
  opacity: 1;
  transform: translateY(1rem);
  transition: transform var(--d) var(--e), opacity var(--d) var(--e);
}

@media (max-width: 576px) {
  .content>*:not(.heading) {
    transform: translateY(2rem);
  }
}

.d_card:hover,
.d_card:focus-within {
  align-items: center;
}

.d_card:hover:before,
.d_card:focus-within:before {
  transform: translateY(-4%);
}

.d_card:hover:after,
.d_card:focus-within:after {
  transform: translateY(-50%);
}

.d_card:hover .content,
.d_card:focus-within .content {
  transform: translateY(0);
}

.d_card:hover .content>*:not(.heading),
.d_card:focus-within .content>*:not(.heading) {
  opacity: 1;
  transform: translateY(0);
  transition-delay: calc(var(--d) / 8);
}

.d_card:focus-within:before,
.d_card:focus-within:after,
.d_card:focus-within .content,
container .card .d_card:focus-within .content>*:not(.heading) {
  transition-duration: 0s;
}

.content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  transition: 0.5s;
}

.content .imgBx .service-text {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 32px;
}

@media (max-width: 576px) {
  .content .imgBx .service-text {
    margin-bottom: 22px;
  }
}

.content .imgBx .service-text p {
  text-decoration: none;
  margin: 0;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  color: var(--white);
}

.content .imgBx .divider-line {
  text-decoration: none;
  border: 1px solid var(--white);
  width: 23px;
}

.content .imgBx h3 {
  text-decoration: none;
  font-size: 28px;
  font-weight: 200;
  line-height: 39.2px;
  color: var(--white);
  margin-bottom: 32px;
}

@media screen and (max-width: 1400px) {
  .content .imgBx h3 {
    font-size: 25px;
  }
}

@media (max-width: 576px) {
  .content .imgBx h3 {
    font-size: 20px;
  }
}

.content .imgBx .pera-hover-text {
  color: var(--white);
  font-family: "Tajawal", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 25.6px;
  padding-top: 10px;
}

@media (max-width: 576px) {
  .content .imgBx .pera-hover-text {
    font-size: 16px;
    padding-top: 5px;
    line-height: 22px;
  }
}

.hover-card:hover .content {
  transform: translateY(-50px);
}

@media (max-width: 992px) {
  .services-details h2 {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 24px;
  }
}

@media (max-width: 991px) {
  .hover-card__wrap {
    display: block;
  }

  .hover-card__wrap .hover-card__inner {
    margin: 20px 0;
  }

  .services-details h2 {
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 24px;
  }
}

/* .....................................Innovation and R&D section............................... */
.innovation-carriere {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 100px;
  margin: 120px 0;
}

.contect-section {
  gap: 160px;
  padding: 0 40px;
}

.innovation-detail .innovation-text {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 32px;
}

.innovation-detail .innovation-text .divider-line {
  border: 1px solid var(--black);
  width: 23px;
}

.innovation-detail h2 {
  color: var(--black);
  font-size: 48px;
  font-weight: 400;
  line-height: 55.2px;
  margin-bottom: 32px;
}

.innovation-detail h2 span {
  color: var(--orange);
  font-size: 48px;
  font-weight: 400;
  line-height: 55.2px;
}

.innovation-detail .innovation-pera {
  font-size: 20px;
  font-weight: 400;
  line-height: 25.6px;
  color: var(--black);
  font-family: "Tajawal", sans-serif;
}

.innovation-text p {
  margin: 0;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  color: var(--black);
  text-transform: uppercase;
}

.innovation-image img {
  min-width: 613px;
  max-width: 613px;
  max-height: 528px;
  border-radius: 20px;
  -o-object-fit: cover;
  object-fit: cover;
}

.Perspectives {
  background-color: var(--white);
  z-index: 2;
  position: relative;
  margin-bottom: 0;
}

.perspective-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.perspective-div .pr-data {
  width: 50%;
}

@media (max-width: 1200px) {
  .contect-section {
    gap: 100px;
    padding: 0 20px;
  }

  .innovation-image img {
    min-width: 400px;
    max-width: 400px;
    width: 100%;
  }
}

@media (max-width: 992px) {
  .innovation-carriere {
    flex-wrap: wrap;
    justify-content: center;
    gap: 60px;
  }

  .innovation-image {
    order: 2;
  }

  .innovation-image img {
    max-width: 500px;
    width: 100%;
  }

  .innovation-detail h2 {
    font-size: 40px;
    line-height: 50px;
  }

  .innovation-detail h2 span {
    font-size: 40px;
    line-height: 50px;
  }

  .perspective-div {
    display: flex;
  }

  .perspective-div .pr-data {
    width: auto;
  }
}

@media (max-width: 767px) {
  .innovation-carriere {
    flex-wrap: wrap;
    justify-content: center;
  }

  .contect-section {
    padding: 0 10px;
    gap: 100px;
  }

  .innovation-image {
    order: 2;
  }

  .innovation-image img {
    min-width: unset;
  }

  .innovation-detail h2 {
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 24px;
  }

  .innovation-detail h2 span {
    font-size: 32px;
    line-height: 42px;
  }

  .perspective-div {
    display: block;
  }

  .perspective-div .pr-data {
    width: 100%;
  }
}

.redirect-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px 8px 16px;
  gap: 12px;
  border-radius: 100px;
  background-color: var(--gray-white);
  max-width: -moz-max-content;
  max-width: max-content;
  margin-top: 32px;
  cursor: pointer;
  transition: all 0.5s;
}

.redirect-btn a {
  text-decoration: none;
  display: flex;
  align-items: center;
}

.redirect-btn p {

  font-size: 16px;
  font-weight: 300;
  line-height: 20px !important;
  color: var(--black);
  margin: 0;
}

.redirect-btn button {
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  margin-left: 10px;
}

.redirect-btn:hover {
  background-color: var(--orange);
}

.redirect-btn:hover p {
  color: var(--white);
}

.carriere-section {
  background-color: #f6f6f5;
  border-radius: 0px 0px 40px 40px;
  padding: 80px 20px;
  margin-top: 80px;
}

@media screen and (max-width: 767px) {
  .carriere-section {
    margin-top: 60px;
    padding: 80px 20px;
  }
}

.carriere-section .innovation-carriere {
  margin: 0;
}

/* ......................................Perspectives section (sliders) .................................................... */
.prespective-sliders {
  position: relative;
}

.prespective-sliders .swiper-container {
  width: 100%;
  margin: 40px 0;
  overflow: hidden;
  padding-top: 20px;
  background: transparent;
  box-shadow: none;
}

.prespective-sliders .swiper-container .swiper-slide {
  width: 386px;
  transition: 0.7s;
}

.prespective-sliders .swiper-container .swiper-slide-active {
  z-index: 1;
  transform: translateY(-20px);
}

.prespective-sliders .swiper-container .swiper-slide-active .slider-card {
  background-color: #ececeb;
}

.prespective-sliders .swiper-container .swiper-slide-active .slider-card .card-img-part .card-direction-btn {
  background-color: var(--orange);
  color: var(--white) !important;
}

.prespective-sliders .swiper-container .swiper-slide-active .slider-card .card-buttons .ser-btn {
  background-color: var(--orange);
  color: var(--white);
}

.prespective-sliders .swiper-container .pre-next-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 40px;
}

.prespective-sliders .swiper-container .pre-next-btn .slide-change-btn {
  background-color: #e6e5e6;
  height: 40px;
  width: 40px;
  border: none;
  border-radius: 50%;
}

.prespective-sliders .swiper-container .pre-next-btn .slide-change-btn:hover {
  background-color: var(--orange);
  color: var(--white);
}

@media (max-width: 576px) {
  .prespective-sliders .swiper-container .swiper-slide {
    width: 350px;
  }

  .prespective-sliders .swiper-container .swiper-slide .slider-card {
    width: 350px;
    margin: 0 auto;
  }
}

@media (max-width: 376px) {
  .prespective-sliders .swiper-container .swiper-slide {
    width: 290px;
  }

  .prespective-sliders .swiper-container .swiper-slide .slider-card {
    width: 290px;
    margin: 0 auto;
  }
}

.slider-card {
  padding: 16px;
  background-color: var(--white);
  border-radius: 20px;
  width: 386px;
  transition: all 0.5s;
}

@media screen and (max-width: 1400px) {
  .slider-card {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .slider-card {
    width: 340px;
  }
}

@media screen and (max-width: 767px) {
  .slider-card {
    width: 386px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 576px) {
  .slider-card {
    width: 290px;
    margin: 0 auto;
  }
}

.slider-card .card-img-part {
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  min-height: 236px;
  max-height: 236px;
  max-width: 354px;
}

.slider-card .card-img-part .img {
  width: auto;
}

.slider-card .card-img-part .card-direction-btn {
  position: absolute;
  right: 20px;
  bottom: 20px;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50%;
}

.slider-card .slider-card-details .card-heading {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #454346;
  margin: 16px 0;
  white-space: nowrap;
  max-width: -moz-fit-content;
  max-width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
}

.slider-card .slider-card-details .card-pera {
  font-family: "Tajawal", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: #454346;
  margin: 16px 0;
}

.slider-card .card-buttons .ser-btn {
  background-color: #dad9da;
  padding: 8px 12px 8px 12px;
  gap: 10px;
  border-radius: 8px;
  color: var(--black);
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  border: none;
}

.slider-card:hover {
  background-color: #ececeb;
}

.slider-card:hover .card-img-part .card-direction-btn {
  background-color: var(--orange);
  color: var(--white) !important;
}

.slider-card:hover .card-buttons .ser-btn {
  background-color: var(--orange);
  color: var(--white);
}

.parallax-scroll {
  overflow: hidden;
}

.parallax-scroll .parallax-content {
  -o-box-align: center;
  align-items: center;
  color: #fff;
  display: -ms-flexbox;
  display: box;
  display: flex;
  font-size: 2rem;
  letter-spacing: 0.1em;
  -o-box-pack: center;
  justify-content: center;
  height: 100%;
  position: relative;
  z-index: 2;
}

.parallax-scroll .parallax-section1 {
  background: #000;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
}

.parallax-scroll .parallax-section1 .parallax-image {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  left: 0;
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  position: absolute;
  transform: translateZ(0);
  width: 100%;
  z-index: 1;
}

.parallax-scroll .parallax-section1 .text-box {
  text-align: center;
  padding: 150px 20px 20px 20px;
  color: var(--white);
  font-size: 80px;
  /* Initial font size */
  font-weight: 400;
  z-index: 2;
}

@media (max-width: 576px) {
  .parallax-scroll .parallax-section1 .text-box {
    font-size: 32px;
  }
}

body:has(.group-section) {
  position: relative;
  z-index: 3;
}

body:has(.group-section)::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 700px;
  background: url(./assets/img/top-bg.svg) no-repeat;
  background-position-x: 700px;
  background-position-y: -100px;
}

@media (max-width: 1460px) {
  body:has(.group-section)::after {
    background-position-x: 500px;
    background-position-y: -100px;
  }
}

@media (max-width: 991px) {
  body:has(.group-section)::after {
    background-position-x: 0px;
    background-position-y: -100px;
  }
}

.group-header {
  padding: 40px 80px;
}

.group-header.group-spacing {
  padding-bottom: 5px;
}

.group-header .gr-h1 {
  font-size: 64px;
  font-family: var(--default-font);
  font-weight: 400;
  line-height: 73.6px;
  color: var(--orange);
  margin-bottom: 32px;
}

.group-header .gr-pera {
  font-family: "Tajawal", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 25.6px;
  text-align: left;
  margin-bottom: 0px;
}

@media (max-width: 992px) {
  .group-header {
    padding: 20px 20px;
  }

  .group-header .gr-h1 {
    font-size: 58px;
    line-height: 60.6px;
    margin-bottom: 28px;
  }

  .group-header .gr-pera {
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  .group-header {
    padding: 20px 25px;
  }

  .group-header .gr-h1 {
    font-size: 48px;
    line-height: 50.6px;
  }

  .group-header .gr-pera {
    font-size: 16px;
  }
}

.main-group-img {
  height: 460px;
  border-radius: 20px;
  overflow: hidden;
  margin: 60px 30px 40px 30px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

/* .main-group-img.the-grp-img {
  background: url(../img/group-header.png);
} */

@media (max-width: 576px) {
  .main-group-img.the-grp-img {
    height: auto !important;
  }
}

/* .main-group-img.soluction-grp-img {
  background: url(../img/soluction-main.png);
} */

@media (max-width: 576px) {
  .main-group-img.soluction-grp-img {
    height: auto !important;
  }
}

/* 
.main-group-img.talents-grp-img {
  background: url(../img/Talents.png);
} */

@media (max-width: 576px) {
  .main-group-img.talents-grp-img {
    height: auto !important;
  }
}

.main-group-img.Perspectives-grp-img {
  /* background: url(../img/Perspectives.jpeg); */
  background-position: bottom;
}

@media (max-width: 576px) {
  .main-group-img.Perspectives-grp-img {
    height: auto !important;
  }
}

.main-group-img.future-grp-img {
  /* background: url(../img/future.jpeg); */
  background-position: bottom;
  height: 550px;
}

@media (max-width: 1200px) {
  .main-group-img.future-grp-img {
    height: auto;
  }

  .main-group-img.future-grp-img .future-challange {
    display: block;
  }
}

@media (max-width: 576px) {
  .main-group-img.future-grp-img {
    height: auto !important;
  }
}

.main-group-img .group-detail {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 54.24%, rgba(0, 0, 0, 0.6) 100%);
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding: 80px 40px;
  gap: 128px;
}

.main-group-img .group-detail .grp-detail-pera {
  font-size: 28px;
  font-weight: 200;
  line-height: 39.2px;
  text-align: left;
  color: var(--white);
  margin-bottom: 0;
  border-left: 2px solid var(--white);
  padding-left: 40px;
}

.main-group-img .prospective-detail .per-heading-section .per-h3 {
  font-size: 24px;
  font-weight: 400;
  line-height: 27.6px;
  color: var(--white);
}

.main-group-img .prospective-detail .per-heading-section .perspect-pera {
  font-family: "Tajawal", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: var(--white);
  margin: 32px 0;
}

@media (max-width: 992px) {
  .main-group-img .group-detail {
    padding: 80px 26px;
    flex-direction: column;
    justify-content: end;
    align-items: flex-start;
    gap: 20px;
  }

  .main-group-img .group-detail .grp-detail-pera {
    font-size: 24px;
    padding-left: 20px;
    line-height: 34px;
  }
}

@media (max-width: 576px) {
  .main-group-img {
    height: auto;
  }

  .main-group-img .group-detail {
    padding: 80px 26px;
    display: block;
  }

  .main-group-img .group-detail .grp-detail-pera {
    font-size: 22px;
    padding-left: 20px;
    margin-bottom: 40px;
    line-height: 30px;
  }
}

.mission-deatil {
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 100px;
  padding: 80px 80px;
}

.mission-deatil.soluction-details {
  padding: 80px 20px;
}

.mission-deatil .soluction-info-1 {
  width: 65%;
}

.mission-deatil .mission-info {
  width: 50%;
}

.mission-deatil .mission-info .mission-title {
  font-size: 48px;
  font-weight: 400;
  line-height: 55.2px;
  color: var(--black);
}

.mission-deatil .mission-info .mission-title span {
  color: var(--orange);
}

.mission-deatil .mission-info .mission-pera-1 {
  color: var(--black);
  font-size: 20px;
  font-weight: 400;
  line-height: 25.6px;
  font-family: "Tajawal", sans-serif;
  padding: 30px 0 0 0;
}

.mission-deatil .mission-info .mission-pera-2 {
  color: var(--black);
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  font-family: "Tajawal", sans-serif;
  padding: 32px 0px;
}

@media (max-width: 992px) {
  .mission-deatil {
    padding: 90px 20px;
    gap: 50px;
  }

  .mission-deatil .soluction-details {
    padding: 100px 20px;
  }

  .mission-deatil .mission-info .mission-title {
    font-size: 40px;
  }

  .mission-deatil .mission-info .mission-pera-2 {
    padding: 20px 0px;
  }
}

@media (max-width: 576px) {
  .mission-deatil {
    padding: 40px 25px !important;
    display: block;
  }

  .mission-deatil .soluction-info-1 {
    width: 100% !important;
  }

  .mission-deatil .mission-info {
    width: 100%;
    margin-bottom: 60px;
  }

  .mission-deatil .mission-info .mission-title {
    font-size: 32px;
    line-height: 36px;
    margin: 0;
  }
}

.group-section {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden !important;
}

.panel_animation {
  width: 90%;
}

@media (max-width: 576px) {
  .panel_animation {
    margin: 0 10px;
    width: 100% !important;
    /* Initial width */
  }
}

.expertise {
  padding: 80px 0px;
}

.expertise .expertise-title .exp-h2 {
  font-size: 48px;
  font-weight: 400;
  line-height: 55.2px;
  margin-bottom: 32px;
}

.expertise .expertise-title .exp-pera {
  font-family: "Tajawal", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 25.6px;
}

.expertise .expertise-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 80px;
  margin-top: 60px;
}

.expertise .expertise-details .expert-img img {
  min-width: 638px;
  max-height: 388px;
  border-radius: 20px;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.expertise .expertise-details .eapert-data p {
  font-family: "Tajawal", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: #646265;
}

@media (max-width: 1200px) {
  .expertise .expertise-details {
    flex-wrap: wrap;
  }

  .expertise .expertise-details .expert-img img {
    min-width: 400px;
    max-height: 388px;
    border-radius: 20px;
    width: 100%;
  }
}

@media (max-width: 576px) {
  .expertise {
    padding: 60px 25px;
  }

  .expertise .expertise-title .exp-h2 {
    font-size: 32px;
    font-weight: 400;
    line-height: 42px;
    margin-bottom: 32px;
  }

  .expertise .expertise-details {
    flex-wrap: wrap;
    gap: 60px;
  }

  .expertise .expertise-details .expert-img img {
    min-width: 300px;
    max-height: 388px;
    border-radius: 20px;
    width: 100%;
  }
}

.our-soluctions .soluction-titles {
  max-width: 700px;
}

.our-soluctions .soluction-titles h2 {
  font-size: 48px;
  font-weight: 400;
  line-height: 55px;
  margin-bottom: 32px;
}

.our-soluctions .soluction-titles p {
  font-family: "Tajawal", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 25.6px;
}

.our-soluctions .soluctions-details-cards {
  margin-top: 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 40px auto;
}

.our-soluctions .soluctions-details-cards .soluction-card {
  max-width: 300px;
  background-color: #F2F2F2;
  border-radius: 20px;
  padding: 30px;
  min-height: 285px;
  max-height: 285px;
}

.our-soluctions .soluctions-details-cards .soluction-card img {
  margin-bottom: 13px;
}

.our-soluctions .soluctions-details-cards .soluction-card .black-divider {
  width: 100%;
  height: 1px;
  background-color: var(--black);
}

@media (max-width: 998px) {
  .our-soluctions .soluctions-details-cards .soluction-card .black-divider {
    margin-top: 0;
  }
}

.our-soluctions .soluctions-details-cards .soluction-card .soluction-h3 {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  margin: 16px 0;
}

.our-soluctions .soluctions-details-cards .soluction-card .soluction-pera {
  font-family: "Tajawal", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  color: #454346;
}

@media (max-width: 576px) {
  .our-soluctions .soluction-titles {
    margin: 10px 25px;
  }

  .our-soluctions .soluction-titles h2 {
    font-size: 32px;
    line-height: 36px;
  }
}

.security-section {
  background-color: #F2F2F2;
  padding: 80px;
  border-radius: 20px;
  margin: 80px auto 0;
}

.security-section .security-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.security-section .security-title .secr-title-value {
  width: 70%;
}

@media (max-width: 1199px) {
  .security-section .security-title .secr-title-value {
    width: 80%;
  }
}

@media (max-width: 991px) {
  .security-section .security-title .secr-title-value {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .security-section .security-title .secr-title-value {
    width: 100%;
  }
}

.security-section .security-title .secr-title-value .serc-h2 {
  font-size: 48px;
  font-weight: 400;
  line-height: 55.2px;
  margin-bottom: 32px;
}

.security-section .security-title .secr-title-value .serc-pera {
  font-family: "Tajawal", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 25.6px;
}

.security-section .security-title .secr-img img {
  width: 100%;
  text-align: center;
}

@media (max-width: 991px) {
  .security-section .security-title .secr-img {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 576px) {
  .security-section .security-title .secr-img {
    width: 100%;
  }
}

.security-section .security-cards {
  margin: 40px 0;
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 60px;
  flex-wrap: wrap;
}

.security-section .security-cards .sec-card {
  max-width: 330px;
}

.security-section .security-cards .sec-card .s-card-img {
  height: 50px;
  width: 50px;
  background-color: var(--orange);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.security-section .security-cards .sec-card .sec-h4 {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  margin: 16px 0;
}

.security-section .security-cards .sec-card .sec-pera {
  font-family: "Tajawal", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
}

@media (max-width: 991px) {
  .security-section .security-title {
    display: block;
  }
}

@media (max-width: 576px) {
  .security-section {
    margin: 80px 25px;
    padding: 40px;
  }

  .security-section .security-title .secr-title-value .serc-h2 {
    font-size: 32px;
    line-height: 42px;
  }
}

.innovation-guide {
  padding: 20px 0 100px;
  width: 100%;
}

.innovation-guide .innovation-h3 {
  font-size: 28px;
  font-weight: 200;
  line-height: 39.2px;
}

.innovation-guide .innovation-cards {
  display: flex;
  align-items: start;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 40px;
}

.innovation-guide .innovation-cards .innovation-guide-card {
  background-color: #F2F2F2;
  max-width: 413px;
  min-height: 272px;
  max-height: 272px;
  border-radius: 20px;
  padding: 32px;
}

.innovation-guide .innovation-cards .innovation-guide-card .innovation-card-header {
  display: flex;
  gap: 20px;
  align-items: start;
  margin-bottom: 20px;
}

.innovation-guide .innovation-cards .innovation-guide-card .innovation-card-header .in-card-img {
  min-width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
}

.innovation-guide .innovation-cards .innovation-guide-card .innovation-card-header .in-card-h4 {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}

.innovation-guide .innovation-cards .innovation-guide-card .innovation-pera {
  font-family: "Tajawal", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: #454346;
}

@media (max-width: 576px) {
  .innovation-guide {
    margin: 20px 0px 100px;
  }

  .innovation-guide .innovation-cards .innovation-guide-card {
    max-width: 300px;
    max-height: unset;
  }
}

.corporate_culture {
  padding: 80px;
}

@media (max-width: 992px) {
  .corporate_culture {
    padding: 80px 40px;
  }
}

.corporate_culture .cc-title {
  font-size: 48px;
  font-weight: 400;
  line-height: 55.2px;
  color: var(--black);
  float: left;
  width: 100%;
  padding: 0 0 30px;
}

.corporate_culture .cc-title span {
  color: var(--orange);
}

.corporate_culture.cc-details {
  padding: 80px 20px;
}

.corporate_culture .cc {
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  gap: 100px;
}

.corporate_culture .cc .cc-info-1 {
  width: 65%;
}

.corporate_culture .cc .cc-info {
  width: 50%;
}

.corporate_culture .cc .cc-info .cc-pera-1 {
  color: var(--black);
  font-size: 20px;
  font-weight: 400;
  line-height: 25.6px;
  font-family: "Tajawal", sans-serif;
}

.corporate_culture .cc .cc-info .cc-pera-2 {
  color: #646265;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  font-family: "Tajawal", sans-serif;
  padding: 32px 0px;
  margin: 0;
}

.corporate_culture .cc .cc-info .redirect-btn {
  margin: 0;
}

@media (max-width: 992px) {
  .corporate_culture .cc {
    padding: 60px 20px;
    gap: 50px;
    display: block;
  }

  .corporate_culture .cc .cc-details {
    padding: 100px 20px;
  }

  .corporate_culture .cc .cc-info {
    width: 100%;
  }

  .corporate_culture .cc .cc-info .cc-title {
    font-size: 40px;
  }

  .corporate_culture .cc .cc-info .cc-pera-2 {
    padding: 20px 0px;
  }
}

@media (max-width: 576px) {
  .corporate_culture .cc {
    padding: 40px 20px !important;
    display: block;
  }

  .corporate_culture .cc .cc-info-1 {
    width: 100% !important;
  }

  .corporate_culture .cc .cc-info {
    width: 100%;
    margin-bottom: 60px;
  }

  .corporate_culture .cc .cc-info .cc-title {
    font-size: 32px;
    line-height: 36px;
    margin: 0;
  }
}

@media (max-width: 576px) {
  .corporate_culture {
    padding: 80px 20px 0;
  }

  .corporate_culture .cc-title {
    font-size: 32px;
    line-height: 42px;
  }
}

.group_figures {
  padding: 0 80px 80px;
}

.group_figures h2 {
  font-size: 28px;
  font-weight: 200;
  line-height: 39.2px;
  color: #454346;
}

.group_figures .figures-cards {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 20px;
  margin-top: 32px;
  overflow: auto;
}

.group_figures .figures-cards ::-webkit-scrollbar {
  height: 0px;
}

.group_figures .figures-cards ::-webkit-scrollbar-thumb {
  background: transperent;
}

.group_figures .figures-cards .group-fig-card {
  padding: 32px;
  border-radius: 20px;
  background-color: #F2F2F2;
  max-width: 285px;
  min-width: 285px;
}

.group_figures .figures-cards .group-fig-card .figute-title {
  display: flex;
  justify-content: start;
  gap: 10px;
  align-items: center;
}

.group_figures .figures-cards .group-fig-card .figute-title .card-title {
  font-family: "Tajawal", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  margin: 0;
  padding-top: 5px;
}

.group_figures .figures-cards .group-fig-card .figur-count {
  padding-left: 32px;
  border-left: 1px solid black;
  margin-top: 32px;
}

.group_figures .figures-cards .group-fig-card .figur-count .count-no {
  font-size: 52px;
  color: var(--black);
  line-height: 60px;
  font-weight: 500;
}

.group_figures .figures-cards .group-fig-card .figur-count .count-pera {
  font-family: "Tajawal", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 25.6px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-bottom: 0;
}

@media (max-width: 576px) {
  .group_figures {
    padding: 0 20px 80px;
  }
}

.group-value {
  padding: 0 80px;
}

@media screen and (max-width: 768px) {
  .group-value {
    padding: 0 20px;
  }
}

.group-value h2 {
  font-size: 28px;
  font-weight: 200;
  line-height: 39.2px;
  color: #454346;
}

.group-value .value-content {
  padding: 30px 0;
}

.group-value .value-content p {
  font-size: 16px;
  font-family: "Tajawal", sans-serif;
  font-weight: 400;
  line-height: 22.4px;
  color: var(--black);
}

.group-block {
  padding: 40px 80px 120px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}

@media screen and (max-width: 991px) {
  .group-block {
    padding: 40px 20px 100px;
  }
}

@media screen and (max-width: 768px) {
  .group-block {
    padding: 20px 20px 100px;
  }
}

.group-block .value-block {
  border-top: 1px solid #000;
  padding: 50px 0 0 0;
  width: calc(33.33% - 20px);
  margin: 50px 0 0 0;
}

@media screen and (max-width: 1200px) {
  .group-block .value-block {
    width: calc(45% - 20px);
  }
}

@media screen and (max-width: 768px) {
  .group-block .value-block {
    width: 100%;
    margin: 10px 0 0 0;
  }
}

.group-block .value-block .title {
  display: flex;
  justify-content: start;
  align-items: center;
}

.group-block .value-block .title h3 {
  font-size: 20px;
  font-weight: 400;
  color: var(--black);
  font-family: var(--default-font);
  line-height: 28px;
  margin: 0 0 0 15px;
}

.group-block .value-block p {
  font-size: 16px;
  font-weight: 400;
  color: #646265;
  font-family: "Tajawal", sans-serif;
  line-height: 22.4px;
  padding: 40px 0 0 0;
}

.recruitment-processes {
  padding: 80px 80px 60px;
}

.recruitment-processes h2 {
  font-size: 48px;
  font-weight: 400;
  line-height: 55.2px;
  color: var(--black);
  float: left;
  width: 100%;
  padding: 0 0 30px;
}

.recruitment-processes p {
  font-family: "Tajawal", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 25.6px;
  text-align: left;
}

@media screen and (max-width: 576px) {
  .recruitment-processes {
    padding: 80px 20px 60px;
  }

  .recruitment-processes h2 {
    font-size: 32px;
    line-height: 42px;
  }
}

.join-capago {
  padding: 60px 80px 60px;
}

.join-capago h2 {
  font-size: 48px;
  font-weight: 400;
  line-height: 55.2px;
  color: var(--black);
  float: left;
  width: 100%;
  padding: 0 0 30px;
}

@media screen and (max-width: 576px) {
  .join-capago {
    padding: 0 20px 100px;
  }

  .join-capago h2 {
    font-size: 32px;
    line-height: 42px;
  }
}

.jcapago-blocklist {
  padding: 0 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.jcapago-blocklist .jcapago-block {
  position: relative;
  max-width: 386px;
  min-width: 386px;
  max-height: 419px;
  min-height: 419px;
  border-radius: 20px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 27.38%, rgba(0, 0, 0, 0.4) 60.06%);
  overflow: hidden;
}

.jcapago-blocklist .jcapago-block .j-card-img {
  border-radius: 20px;
  position: relative;
  z-index: -1;
  width: 100%;
  transition: 0.4s;
}

.jcapago-blocklist .jcapago-block .arrow-button {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 32px;
  right: 32px;
}

.jcapago-blocklist .jcapago-block .arrow-button img {
  width: 12px;
  height: 12px;
}

.jcapago-blocklist .jcapago-block .card-contant {
  position: absolute;
  bottom: 32px;
  left: 32px;
  right: 32px;
}

.jcapago-blocklist .jcapago-block img {
  height: 100%;
}

.jcapago-blocklist .jcapago-block .arrow {
  position: absolute;
  top: 52px;
  right: 32px;
}

.jcapago-blocklist .jcapago-block h5 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  color: var(--white);
  margin-bottom: 32px;
}

.jcapago-blocklist .jcapago-block p {
  margin-bottom: 32px;
  font-family: "Tajawal", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22.4px;
  color: var(--white);
}

.jcapago-blocklist .jcapago-block .redirect-btn {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  width: 100%;
  max-width: unset;
}

.jcapago-blocklist .jcapago-block .redirect-btn h4 {
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  font-family: "Tajawal", sans-serif;
  color: var(--white);
  margin: 0;
}

.jcapago-blocklist .jcapago-block .r-arrow img {
  height: 12px;
  width: 12px;
}

.jcapago-blocklist .jcapago-block:hover .j-card-img {
  transform: scale(1.15);
}

.jcapago-blocklist .jcapago-block:hover .arrow-button {
  background-color: var(--orange);
  color: var(--white);
}

.jcapago-blocklist .jcapago-block:hover .redirect-btn {
  background: var(--white);
}

.jcapago-blocklist .jcapago-block:hover .redirect-btn h4 {
  color: var(--black);
}

.jcapago-blocklist .jcapago-block:hover .redirect-btn .r-arrow {
  background-color: var(--orange);
  color: var(--white);
}

@media screen and (max-width: 576px) {
  .jcapago-blocklist {
    padding: 0 20px 40px;
    flex-wrap: wrap;
  }

  .jcapago-blocklist .jcapago-block {
    max-width: 300px;
    min-width: 300px;
    max-height: unset;
    min-height: unset;
  }
}

.block-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  padding: 0 80px 100px;
}

.block-list .rp-block {
  width: calc(33.33% - 20px);
}

@media screen and (max-width: 1200px) {
  .block-list .rp-block {
    width: calc(45% - 20px);
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  .block-list .rp-block {
    width: calc(100% - 20px);
    height: 100%;
  }
}

.block-list .rp-block .process-block {
  background: #f2f2f2;
  padding: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 20px;
}

@media screen and (max-width: 1200px) {
  .block-list .rp-block .process-block {
    display: block;
  }
}

.block-list .rp-block .process-block .number {
  width: 67px;
  height: 67px;
  background: #F9F9F8;
  text-align: center;
  line-height: 67px;
  border-radius: 100%;
  font-family: var(--default-font);
  font-size: 32px;
  font-weight: 200;
  color: #454346;
  max-width: 67px;
  min-width: 67px;
}

.block-list .rp-block .process-block .process-title {
  padding: 0 0 0 30px;
}

@media screen and (max-width: 1200px) {
  .block-list .rp-block .process-block .process-title {
    padding: 30px 0 0 0;
  }
}

.block-list .rp-block .process-block .process-title h4 {
  font-size: 16px;
  font-weight: 400;
  color: var(--black);
  font-family: var(--default-font);
  line-height: 20px;
}

.block-list .rp-block .process-block .process-title p {
  font-size: 16px;
  font-weight: 400;
  color: #646265;
  font-family: "Tajawal", sans-serif;
  line-height: 22.4px;
  padding: 10px 0 0 0;
}

@media screen and (max-width: 576px) {
  .block-list {
    padding: 0 20px 100px;
  }
}

.career-evolution {
  padding: 80px 80px 0;
}

.career-evolution h2 {
  font-size: 48px;
  font-weight: 400;
  line-height: 55.2px;
  color: var(--black);
  float: left;
  width: 100%;
  padding: 0 0 30px;
}

.career-evolution p {
  font-size: 20px;
  font-weight: 400;
  color: var(--black);
  font-family: "Tajawal", sans-serif;
  line-height: 25.6px;
  padding: 10px 0 0 0;
}

@media screen and (max-width: 576px) {
  .career-evolution {
    padding: 0 20px 40px;
  }

  .career-evolution h2 {
    font-size: 32px;
    line-height: 42px;
  }
}

.career-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 80px;
  margin-top: 60px;
  padding: 0 80px 80px;
}

@media screen and (max-width: 1200px) {
  .career-details {
    display: block;
  }
}

@media screen and (max-width: 576px) {
  .career-details {
    padding: 0 20px 80px;
    margin-top: 40px;
  }
}

.career-details .career-img img {
  width: 50%;
  max-height: 388px;
  border-radius: 20px;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

@media screen and (max-width: 1200px) {
  .career-details .career-img img {
    width: 100%;
    margin-bottom: 60px;
  }
}

.career-details .career-data {
  width: 50%;
}

@media screen and (max-width: 1200px) {
  .career-details .career-data {
    width: 100%;
  }
}

.career-details .career-data p {
  font-family: "Tajawal", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: #646265;
}

.professions_slider {
  touch-action: pan-y pinch-zoom;
  border-radius: 10px;
  background-color: #f2f2f2;
  padding: 20px;
  width: 100%;
  position: relative;
}

.swiper-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 1;
  position: relative;
}

.swiper-container {
  background: linear-gradient(270deg, #f7f9ff 0%, #f2f6ff 100%);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  width: 100%;
  position: relative;
  max-width: 100%;
  border-radius: 10px;
}

.slider-image-wrapper {
  height: 100%;
  border-radius: 20px;
  width: 45%;
  overflow: hidden;
}

@media screen and (max-width: 998px) {
  .slider-image-wrapper {
    width: 100%;
  }
}

.slider-item {
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-shrink: 0;
  opacity: 0;
  background: #F2F2F2 !important;
  cursor: grab;
  padding: 20px;
}

@media screen and (max-width: 998px) {
  .slider-item {
    display: block;
  }
}

.slider-item-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: 0.4s;
  width: 55%;
  padding: 27px 80px 56px 60px;
}

@media screen and (max-width: 1028px) {
  .slider-item-content {
    padding: 27px 40px 56px 40px;
  }
}

@media screen and (max-width: 998px) {
  .slider-item-content {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .slider-item-content {
    padding: 27px 20px 56px 20px;
  }
}

.slider-item-content h1 {
  margin: 0 0 32px;
  font-weight: 400;
  font-size: 48px;
  line-height: 55.2px;
  letter-spacing: -4%;
  color: #000000;
  transform: translateY(20px);
  transition: all 0.4s ease;
  transition-delay: 0.2s;
  overflow: hidden;
  max-width: 100%;
}

.slider-item-content h1 span {
  color: #EC6630;
}

@media screen and (max-width: 520px) {
  .slider-item-content h1 {
    font-size: 32px;
    line-height: 42px;
  }
}

.slider-item-content p {
  font-size: 20px;
  line-height: 25.6px;
  font-weight: 400;
  font-family: "Tajawal", sans-serif;
  color: var(--black);
  transform: translateY(20px);
  transition: all 0.4s ease;
  transition-delay: 0.3s;
  margin-bottom: 40px;
}

@media screen and (max-width: 520px) {
  .slider-item-content p {
    font-size: 14px;
    line-height: 20px;
  }
}

.slider-content .slider-icon-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
}

.slider-content .slider-icon-content h3 {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: var(--black);
  margin: 0;
}

.slider-content p {
  margin-top: 13px;
  margin-bottom: 40px;
  font-weight: 400;
  color: #646265;
  font-size: 16px;
  line-height: 1.4;
  font-family: "Tajawal", sans-serif;
  overflow-y: auto;
}

@media screen and (max-width: 1200px) {
  .slider-content p {
    /* min-height: 200px; */
  }
}

@media screen and (max-width: 991px) {
  .slider-content p {
  min-height: 200px;
  }
}

@media screen and (max-width: 767px) {
  .slider-content p {
  min-height: 200px;
  }
}

@media screen and (max-width: 576px) {
  .slider-content p {
min-height: 200px;
  }
}

.slider-btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.slider-btn-container .redirect-btn h4 {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: var(--black);
  margin: 0;
}

.slider-btn-container .redirect-btn:hover h4 {
  color: var(--white);
}

.slider-item-content>* {
  opacity: 1;
  transform: translateY(20px);
}

.swiper-slide-active .slider-item-content>* {
  transform: translateY(0px);
  opacity: 1;
}

.slider-image {
  /* height: 500px; */
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: 0.2s;
}

/* .swiper-pagination {
  position: absolute;
  left: 25% !important;
  bottom: 8px;
  transform: translatex(-25%) !important;
  z-index: 1;
  width: auto !important;
}

@media screen and (max-width: 998px) {
  .swiper-pagination {
    left: 45% !important;
  }
}

.swiper-pagination-bullet {
  border-radius: 0;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  line-height: 30px;
  font-size: 12px;
  opacity: 1;
  background: #DCDCDC;
  display: inline-block;
  margin-right: 8px;
  cursor: pointer;
  transition: all 0.2s;
}

.swiper-pagination-bullet-active {
  background: var(--orange) !important;
  border-radius: 10px;
} */

.slick-dots {
  position: absolute;
  left: 25% !important;
  bottom: 8px;
  transform: translateX(-25%) !important;
  z-index: 1;
  width: auto !important;
}

@media screen and (max-width: 998px) {
  .slick-dots {
    left: 45% !important;
  }
}

/* Style individual dots */
.slick-dots li button {
  border-radius: 0;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  line-height: 30px;
  font-size: 12px;
  opacity: 1;
  background: #DCDCDC;
  display: inline-block;
  margin-right: 8px;
  cursor: pointer;
  transition: all 0.2s;
}
.slick-dots li button::before{
  opacity: 0;
}
/* Style the active dot */
.slick-dots li.slick-active button {
  background: var(--orange) !important;
  border-radius: 10px;
}


.slick-dots li.slick-active button::before {
 opacity: 0;
}
.slider-buttons {
  position: absolute;
  display: flex;
  top: 100%;
  justify-content: flex-end;
  width: 100%;
  padding-top: 8px;
}

.swiper-button-next,
.swiper-button-prev {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  color: var(--white);
  position: relative;
  margin-left: 4px;
}

.swiper-button-next:before,
.swiper-button-prev:before {
  content: "";
  position: absolute;
  background-color: #fff;
  height: 1px;
  width: 0;
  left: 0;
  bottom: -1px;
  transition: 0.2s;
}

.swiper-button-next:hover:before,
.swiper-button-prev:hover:before {
  width: 100%;
}

.socials {
  position: fixed;
  top: 12px;
  right: 16px;
  display: flex;
  align-items: center;
}

.socials .social-link {
  display: inline-block;
  margin-left: 8px;
  color: #fff;
}

@media screen and (max-width: 520px) {

  .swiper-button-next:hover:before,
  .swiper-button-prev:hover:before {
    display: none;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.all-categories {
  padding: 40px 80px 120px;
}

.all-categories .category-header {
  font-size: 28px;
  font-weight: 200;
  line-height: 39.2px;
  color: var(--black);
  margin-bottom: 32px;
}

.all-categories .cat-button {
  border-radius: 100px;
  border: 1px solid black;
  padding: 13px 16px;
  background-color: var(--white);
  transition: 0.3s;
  margin: 8px;
}

.all-categories .cat-button:hover {
  background: #F2F2F2;
  border: 1px solid transparent;
}

.all-categories .cat-button.active {
  background-color: var(--orange);
  color: white;
  border: 1px solid transparent;
}

.all-categories .perspect-ul {
  list-style: none;
  margin: 60px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
  padding: 0;
}

.all-categories .perspect-ul .element-item {
  width: calc(33.3333333333% - 30px);
  margin: 10px 15px;
}

@media screen and (max-width: 1400px) {
  .all-categories .perspect-ul .element-item {
    width: calc(50% - 20px);
    margin: 10px auto;
  }
}

@media screen and (max-width: 767px) {
  .all-categories .perspect-ul .element-item {
    width: 100%;
  }
}

.all-categories .perspect-ul .element-item .perspect-card {
  padding: 16px;
  background-color: var(--white);
  border-radius: 20px;
  width: 100%;
}

@media screen and (max-width: 991px) {
  .all-categories .perspect-ul .element-item .perspect-card {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .all-categories .perspect-ul .element-item .perspect-card {
    width: 100%;
    margin: 0 auto;
  }
}

.all-categories .perspect-ul .element-item .perspect-card .perspect-card-img-part {
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  min-height: 236px;
  max-height: 236px;
  max-width: 100%;
}

.all-categories .perspect-ul .element-item .perspect-card .perspect-card-img-part img {
  min-width: 100%;
}

.all-categories .perspect-ul .element-item .perspect-card .perspect-card-img-part .per-card-direction-btn {
  position: absolute;
  right: 20px;
  bottom: 20px;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50%;
}

.all-categories .perspect-ul .element-item .perspect-card .perspect-card-details .per-card-heading {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #454346;
  margin: 16px 0;
  white-space: nowrap;
  max-width: -moz-fit-content;
  max-width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
}

.all-categories .perspect-ul .element-item .perspect-card .perspect-card-details .per-card-pera {
  font-family: "Tajawal", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: #454346;
  margin: 16px 0;
}

.all-categories .perspect-ul .element-item .perspect-card .perspect-card-buttons .per-btn {
  background-color: #dad9da;
  padding: 8px 12px 8px 12px;
  gap: 10px;
  border-radius: 8px;
  color: var(--black);
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  border: none;
}

.all-categories .perspect-ul .element-item .perspect-card:hover {
  background-color: #ececeb;
}

.all-categories .perspect-ul .element-item .perspect-card:hover .perspect-card-img-part .per-card-direction-btn {
  background-color: var(--orange);
  color: var(--white) !important;
}

.all-categories .perspect-ul .element-item .perspect-card:hover .perspect-card-buttons .per-btn {
  background-color: var(--orange);
  color: var(--white);
}

@media screen and (max-width: 1200px) {
  .all-categories {
    padding: 40px 20px 120px;
  }

  .all-categories .perspect-ul {
    justify-content: center;
  }

  .all-categories .perspect-ul .element-item .perspect-card {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .all-categories .perspect-ul .element-item .perspect-card {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .all-categories {
    padding: 40px 0px 120px;
  }

  .all-categories .perspect-ul .element-item .perspect-card {
    width: 100%;
  }
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: var(--swiper-pagination-bottom, 27px) !important;
}

.future-challange {
  background-color: rgba(0, 0, 0, 0.1215686275);
  height: 100%;
  padding: 40px 80px;
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 32px;
}

.future-challange .challanges-details .back-button {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 13px;
  margin-bottom: 32px;
}

.future-challange .challanges-details .back-button .arrow-btn {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: var(--white);
  color: var(--black);
}

.future-challange .challanges-details .back-button .back-text {
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  color: var(--white);
  margin: 0px;
}

.future-challange .challanges-details .challange-text {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 32px;
}

.future-challange .challanges-details .challange-text p {
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  color: var(--white);
  margin: 0;
}

.future-challange .challanges-details .challange-text .divider-line {
  border: 1px solid var(--white);
  width: 23px;
}

.future-challange .challanges-details .future-h2 {
  font-size: 64px;
  font-weight: 400;
  line-height: 73.6px;
  color: var(--white);
  margin-bottom: 20px;
}

@media screen and (max-width: 991px) {
  .future-challange .challanges-details .future-h2 {
    font-size: 38px;
    line-height: 48px;
  }
}

.future-challange .challanges-details .future-pera {
  font-family: "Tajawal", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 25.6px;
  text-align: left;
  color: var(--white);
  margin-top: 30px;
}

.future-challange .social-share-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.future-challange .social-share-btns .soc-btn {
  padding: 10px 14px;
  gap: 4px;
  border-radius: 8px;
  opacity: 0px;
  border: none;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
}

@media screen and (max-width: 576px) {
  .future-challange {
    padding: 40px 20px;
  }
}

.artical-data-values {
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 100px;
  position: relative;
  padding-top: 40px;
}

.artical-data-values .artical-links {
  min-width: 280px;
  position: sticky;
  top: 0;
}

.artical-data-values .artical-links .list-name-icon {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  margin: 22px 0px;
}

.artical-data-values .artical-links .list-name-icon i {
  margin-right: 20px;
}

.artical-data-values .artical-links .tag-ul {
  padding-left: 0;
  margin-top: 20px;
}

.artical-data-values .artical-links .tag-ul .tag-li {
  list-style: none;
  padding: 12px 20px;
  border-radius: 20px;
}

.artical-data-values .artical-links .tag-ul .tag-li a {
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  color: var(--black);
}

.artical-data-values .artical-links .tag-ul .tag-li:hover {
  background-color: #F2F2F2;
}

.artical-data-values .artical-detail .artical-main-pera {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: #454346;
}

.artical-data-values .artical-detail .artical-data .artical-h3 {
  font-size: 28px;
  font-weight: 200;
  line-height: 39.2px;
  color: var(--black);
}

.artical-data-values .artical-detail .artical-data .artical-h4 {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: var(--black);
}

.artical-data-values .artical-detail .artical-data .artical-pera {
  font-family: "Tajawal", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 25.6px;
  color: #454346;
}

@media screen and (max-width: 1200px) {
  .artical-data-values {
    flex-wrap: wrap;
    gap: 40px;
  }

  .artical-data-values .black-divider {
    margin-top: 40px;
  }

  .artical-data-values .artical-links {
    position: unset;
  }
}

.future {
  border-radius: 0 0 40px 40px;
  background-color: var(--white);
}

.future .find-data-cards {
  padding: 80px 0;
}

.future .find-data-cards .find-text {
  font-size: 48px;
  font-weight: 400;
  line-height: 55.2px;
  text-align: left;
  margin-bottom: 60px;
}

.future .find-data-cards .future-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
}

@media screen and (max-width: 1200px) {
  .future .find-data-cards .future-cards {
    flex-wrap: wrap;
  }
}

.grey-bg {
  background: #F2F2F2;
}

.contact-us {
  width: 100%;
  padding: 80px;
  background: #F2F2F2;
}

.contact-us .conatct-details {
  padding: 0 80px;
}

.contact-us .conatct-details .detail .need-contact {
  font-size: 64px;
  font-weight: 400;
  line-height: 73.6px;
  color: var(--orange);
  margin-bottom: 32px;
}

.contact-us .conatct-details .detail .contect-pera {
  font-family: "Tajawal", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 25.6px;
  color: var(--black);
  margin-bottom: 83px;
}

.contact-us .conatct-details .detail .head-office .head-h4 {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: var(--black);
  margin: 32px 0;
}

.contact-us .conatct-details .detail .head-office .office-detail {
  margin-bottom: 32px;
}

.contact-us .conatct-details .detail .head-office .office-detail .detail-h5 {
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  color: var(--black);
}

.contact-us .conatct-details .detail .head-office .office-detail .detail-pera {
  font-family: "Tajawal", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: var(--black);
  margin-bottom: 0;
}

.contact-us .conatct-details .contact-form {
  background: var(--white);
  padding: 28px;
  border-radius: 10px;
}

.contact-us .conatct-details .contact-form .form-header {
  font-size: 48px;
  font-weight: 400;
  line-height: 55.2px;
  color: var(--black);
  margin-bottom: 16px;
}

.contact-us .conatct-details .contact-form .form-pera {
  color: #454346;
  font-family: "Tajawal", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  margin-bottom: 32px;
}

.contact-us .conatct-details .contact-form .form .form-control {
  padding: 12px;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid #C1C0C1;
}

.contact-us .conatct-details .contact-form .form .form-control.text-message {
  resize: none;
}

.contact-us .conatct-details .contact-form .form .send-btn {
  padding: 8px 8px 8px 16px;
  gap: 12px;
  border-radius: 100px;
  background-color: #F2F2F2;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
}

.contact-us .conatct-details .contact-form .form .send-btn:hover {
  background-color: var(--orange);
  color: var(--white);
}

.contact-us .conatct-details .contact-form .form .forword-mail {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1200px) {
  .contact-us .conatct-details {
    padding: 40px;
  }
}

@media screen and (max-width: 991px) {
  .contact-us .conatct-details {
    padding: 20px 20px;
  }

  .contact-us .conatct-details .detail .need-contact {
    font-size: 48px;
    font-weight: 400;
    line-height: 55.2px;
  }
}

@media screen and (max-width: 767px) {
  .contact-us {
    padding: 10px 40px;
  }

  .contact-us .conatct-details .contact-form {
    padding: 16px;
  }

  .contact-us .conatct-details .contact-form .form-header {
    font-size: 32px;
    line-height: 36px;
  }
}

@media screen and (max-width: 576px) {
  .contact-us {
    padding: 10px 0px;
  }

  .contact-us .conatct-details {
    padding: 20px 0px;
  }
}

.visa-center {
  width: 100%;
  padding: 80px 80px;
  background: var(--white);
  border-radius: 0 0 40px 40px;
  background-image: url(./assets/img/top-bg.svg);
  background-repeat: no-repeat;
  background-position: top right;
}

.visa-center .visa-contact {
  padding: 0 80px;
}

.visa-center .visa-contact .visa-heading {
  font-size: 48px;
  font-weight: 400;
  line-height: 55.2px;
  color: black;
  margin-bottom: 60px;
}

.visa-center .visa-contact .visa-heading span {
  color: var(--orange);
}

.visa-center .visa-contact .office-detail {
  padding: 34px 0;
  border-top: 1px solid black;
}

.visa-center .visa-contact .office-detail .office-h5 {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: var(--black);
  margin-bottom: 33px;
}

.visa-center .visa-contact .office-detail .office-h6 {
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  text-align: left;
  color: var(--black);
  margin-bottom: 25px;
}

.visa-center .visa-contact .office-detail .office-pera {
  color: var(--black);
  font-family: "Tajawal", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 22.4px;
  margin-bottom: 0;
}

@media screen and (max-width: 1200px) {
  .visa-center .visa-contact {
    padding: 40px;
  }
}

@media screen and (max-width: 991px) {
  .visa-center .visa-contact {
    padding: 40px 0px;
  }
}

@media screen and (max-width: 767px) {
  .visa-center {
    padding: 80px 40px;
  }

  .visa-center .visa-contact .visa-heading {
    font-size: 32px;
    line-height: 36px;
  }
}

@media screen and (max-width: 576px) {
  .visa-center {
    padding: 40px 0px;
  }
}

.terms-group-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.terms-group-section .terms-data-img {
  background-image: url(./assets/img/top-bg.svg);
  background-color: #F2F2F2;
  height: 460px;
  border-radius: 20px;
  overflow: hidden;
  margin: 0px 30px 40px 30px;
  background-size: 786px;
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 80px;
  width: 100%;
}

.terms-group-section .terms-details {
  display: flex;
  gap: 124px;
  flex-direction: column;
}

.terms-group-section .terms-details .condition-h1 {
  color: var(--orange);
  font-size: 64px;
  font-weight: 400;
  line-height: 73.6px;
  margin-bottom: 16px;
}

.terms-group-section .terms-details .condition-date {
  font-family: "Tajawal", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 25.6px;
}

.terms-group-section .terms-details .condition-line {
  width: 77px;
  border: 1px solid black;
  margin-bottom: 32px;
}

.terms-group-section .terms-details .condition-pera {
  font-family: "Tajawal", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 25.6px;
}

@media screen and (max-width: 991px) {
  .terms-group-section .terms-data-img {
    padding: 80px 40px;
    height: auto;
  }

  .terms-group-section .terms-details .condition-h1 {
    font-size: 48px;
    font-weight: 400;
    line-height: 55.2px;
  }
}

@media screen and (max-width: 576px) {
  .terms-group-section .terms-data-img {
    padding: 40px 20px;
    height: auto;
  }

  .terms-group-section .terms-details .condition-h1 {
    font-size: 32px;
    font-weight: 400;
    line-height: 42px;
  }
}

.terms-details-values {
  padding: 80px;
}

.terms-details-values .terms-notes {
  margin-bottom: 40px;
}

.terms-details-values .terms-notes .term-heading {
  font-size: 28px;
  font-weight: 200;
  line-height: 39.2px;
  color: var(--black);
  margin-bottom: 20px;
}

.terms-details-values .terms-notes .term-sub-heading {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: var(--black);
  margin-bottom: 20px;
}

.terms-details-values .terms-notes .terms-pera {
  font-family: "Tajawal", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 25.6px;
  color: #454346;
  margin-bottom: 25px;
}

.terms-details-values .terms-notes .terms-ul {
  margin-bottom: 20px;
}

.terms-details-values .terms-notes .terms-ul .terms-li {
  font-family: "Tajawal", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 25.6px;
  color: #454346;
}

.terms-details-values .terms-notes .terms-ul .artical-li {
  font-family: "Tajawal", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 25.6px;
  text-align: left;
  color: #454346;
}

.terms-details-values .terms-notes .terms-ul .artical-li span {
  font-family: var(--default-font);
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  text-align: left;
}

@media screen and (max-width: 991px) {
  .terms-details-values {
    padding: 80px 40px;
  }
}

@media screen and (max-width: 576px) {
  .terms-details-values {
    padding: 80px 10px;
  }
}

.innovation-radius {
  background-color: #ffffff;
  border-radius: 0 0 40px 40px;
}

.hub {
  width: 100%;
  min-height: 100vh;
}

.hub .hub-section {
  float: left;
  width: 100%;
  min-height: 100vh;
  background: #000000;
}

.hub .hub-section .hub-video {
  float: left;
  width: 100%;
  min-height: 100vh;
  z-index: 2;
  position: relative;
  -o-object-fit: cover;
  object-fit: cover;
}

.hub .hub-section .hub-content {
  position: absolute;
  z-index: 3;
  width: 100%;
  padding: 40px 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.2784313725);
}

.hub .hub-section .hub-content .hub-cont {
  float: left;
  width: 100%;
  text-align: center;
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hub .hub-section .hub-content .hub-cont .hub-text {
  text-transform: uppercase;
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  color: #fff;
}

.hub .hub-section .hub-content .hub-cont h1 {
  font-size: 80px;
  font-weight: 400;
  line-height: 92px;
  color: #fff;
  text-align: center;
}

@media screen and (max-width: 576px) {
  .hub .hub-section .hub-content .hub-cont h1 {
    font-size: 52px;
    line-height: 59px;
  }
}

.hub .hub-section .hub-content .hub-cont .country_flag {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
}

.hub .hub-section .hub-content .hub-cont .country_flag .flag {
  width: 62px;
  height: 62px;
  border-radius: 100%;
  background: #fff;
  margin: 0 17px;
}

@media screen and (max-width: 576px) {
  .hub .hub-section .hub-content .hub-cont .country_flag {
    margin-bottom: 22px;
  }
}

.hub .hub-section .hub-content .hub-cont .demand-devis {
  width: 100%;
  border-radius: 16px;
  padding: 28px;
  float: left;
  text-align: center;
  max-width: 418px;
  margin: 3vh 0;
  background: rgba(255, 255, 255, 0.192);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(9.5px);
  -webkit-backdrop-filter: blur(9.5px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hub .hub-section .hub-content .hub-cont .demand-devis p {
  font-size: 16px;
  line-height: 22.4px;
  font-weight: 300;
  color: #fff;
  margin-bottom: 0;
}

.hub .hub-section .hub-content .hub-cont .demand-devis .d-card-title {
  font-family: "Tajawal", sans-serif;
}

.hub .hub-section .hub-content .hub-cont .demand-devis .valider-button {
  padding: 8px 8px 8px 16px;
  background: rgba(255, 255, 255, 0.459);
  backdrop-filter: blur(9.5px);
  -webkit-backdrop-filter: blur(9.5px);
  border-radius: 50px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
}

.hub .hub-section .hub-content .hub-cont .demand-devis .valider-btn-arrow {
  padding: 12px;
  background-color: #fff;
}

.hub .hub-section .hub-content .hub-cont .demand-devis .select-menu {
  max-width: 100%;
  margin: 50px auto;
  width: 100%;
}

.hub .hub-section .hub-content .hub-cont .demand-devis .select-menu .select-btn {
  display: flex;
  height: 55px;
  background: #fff;
  padding: 8px 8px 8px 16px;
  font-size: 18px;
  font-weight: 400;
  border-radius: 50px;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.hub .hub-section .hub-content .hub-cont .demand-devis i {
  background-color: #EC6630;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.hub .hub-section .hub-content .hub-cont .demand-devis .select-menu .options {
  position: absolute;
  width: 330px;
  overflow-y: auto;
  max-height: 295px;
  padding: 10px;
  margin-top: 10px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  animation-name: fadeInDown;
  -webkit-animation-name: fadeInDown;
  animation-duration: 0.35s;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.35s;
  -webkit-animation-fill-mode: both;
}

@media screen and (max-width: 576px) {
  .hub .hub-section .hub-content .hub-cont .demand-devis .select-menu .options {
    width: 250px;
  }
}

.hub .hub-section .hub-content .hub-cont .demand-devis .select-menu .options .option {
  display: flex;
  height: 55px;
  cursor: pointer;
  padding: 0 16px;
  border-radius: 8px;
  align-items: center;
  background: #fff;
}

.hub .hub-section .hub-content .hub-cont .demand-devis .select-menu .options .option:hover {
  background: #f2f2f2;
}

.hub .hub-section .hub-content .hub-cont .demand-devis .select-menu .options .option i {
  font-size: 25px;
  margin-right: 12px;
}

.hub .hub-section .hub-content .hub-cont .demand-devis .select-menu .options .option .option-text {
  font-size: 18px;
  color: #333;
}

.hub .hub-section .hub-content .hub-cont .demand-devis .select-btn i {
  font-size: 25px;
  transition: 0.3s;
}

.hub .hub-section .hub-content .hub-cont .demand-devis .select-menu.active .select-btn i {
  transform: rotate(-180deg);
}

.hub .hub-section .hub-content .hub-cont .demand-devis .select-menu.active .options {
  display: block;
  opacity: 0;
  z-index: 10;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 30px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fadeInDown {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  to {
    transform: translate3d(0, 20px, 0);
    opacity: 0;
  }
}

@media screen and (max-width: 576px) {
  .hub .hub-section .hub-content .hub-cont .demand-devis {
    -webkit-backdrop-filter: unset;
    backdrop-filter: unset;
  }
}

.hub .navbar-btn-demande {
  background-color: var(--demande-btn-bgcolor);
  padding: 8px 8px 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 100px;
  gap: 12px;
  cursor: pointer;
  transition: all 0.5s;
  max-width: 152px;
  z-index: 1;
}

.hub .navbar-btn-demande a {
  display: flex;
  align-items: center;
}

.hub .navbar-btn-demande p {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: var(--black);
  line-height: 17.5px;
}

.hub .navbar-btn-demande button {
  border: none;
  height: 34px;
  width: 34px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
}

.hub .navbar-btn-demande:hover {
  background-color: var(--orange);
}

.hub .navbar-btn-demande:hover p {
  color: var(--black);
}

.hero-detail-section {
  margin-top: 80px;
}

/*# sourceMappingURL=style.css.map */